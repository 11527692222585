var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "plan-cards",
    class: _vm.$mq
  }, [_vm._l(_vm.plans, function (plan, i) {
    return [plan.main_position === 1 ? _c('div', {
      key: i,
      staticClass: "item",
      class: _vm.$mq
    }, [_c('div', {
      staticClass: "item-content"
    }, [_c('div', {
      staticClass: "name"
    }, [_vm._v(_vm._s(plan.name))]), _c('div', {
      staticClass: "price"
    }, [_vm.calculated ? [_vm._v(" " + _vm._s(_vm.calculated.items[i].finalPrice.toLocaleString()) + " р. / месяц ")] : [_vm._v("   ")]], 2), _c('div', {
      staticClass: "old-price"
    }, [_vm.calculated && _vm.calculated.items[i].basePrice !== _vm.calculated.items[i].finalPrice ? [_vm._v(" " + _vm._s(_vm.calculated.items[i].basePrice.toLocaleString()) + " р. ")] : _vm._e()], 2), _vm.calculatedForPeriod && _vm.calculatedForPeriod.months > 1 ? _c('div', {
      staticClass: "price-disclaimer"
    }, [_vm._v(" * при оплате за " + _vm._s(_vm.calculatedForPeriod.months) + " "), _vm.calculatedForPeriod.months == 3 || _vm.calculatedForPeriod.months == 24 ? _c('span', [_vm._v("месяца")]) : _c('span', [_vm._v("месяцев")])]) : _vm._e(), _c('div', {
      staticClass: "hr"
    }), _c('div', {
      staticClass: "features"
    }, [_c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v(" Внешняя аналитика Wildberries ")])], 1), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v("Внешняя аналитика Ozon")])], 1), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v("Продвинутые рейтинги")])], 1), _vm._m(0, true), _vm._m(1, true), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconX', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v("Дополнительные пользователи")])], 1), _vm._m(2, true), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v(" Подключение своего магазина по API ")])], 1), _vm._m(3, true), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _vm._m(4, true)], 1), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v("Подбор поисковых запросов")])], 1), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _vm._m(5, true)], 1), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v("Плагин для браузера")])], 1), _vm._m(6, true), _vm._m(7, true), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _vm._m(8, true)], 1), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconX', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v("Мониторинг РРЦ")]), _c('NewFeature', {
      staticClass: "label",
      attrs: {
        "type": "green"
      }
    })], 1), _vm._m(9, true), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconX', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v(" Репрайсер (управление ценами) ")]), _c('NewFeature', {
      staticClass: "label",
      attrs: {
        "type": "green"
      }
    })], 1), _vm._m(10, true), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _vm._m(11, true), _c('NewFeature', {
      staticClass: "label",
      attrs: {
        "type": "green"
      }
    })], 1), _vm._m(12, true), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v("Тренды запросов")]), _c('NewFeature', {
      staticClass: "label",
      attrs: {
        "type": "green"
      }
    })], 1), _c('div', {
      staticClass: "features-item"
    }), _c('div', {
      staticClass: "features-item"
    }), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconX', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v("Сравнение карточек")]), _c('NewFeature', {
      staticClass: "label",
      attrs: {
        "type": "green"
      }
    })], 1)]), _c('div', {
      staticClass: "action"
    }, [_c('PButton', {
      attrs: {
        "block": "",
        "tag": "a",
        "variant": "outlined"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onChoose(plan);
        }
      }
    }, [_vm._v(_vm._s(_vm.isPlanActive(plan) ? "Продлить тариф" : "Выбрать тариф"))])], 1)])]) : _vm._e(), plan.main_position === 2 ? _c('div', {
      key: i,
      staticClass: "item highlighted",
      class: _vm.$mq
    }, [_c('div', {
      staticClass: "item-before"
    }, [_vm._v("Наиболее популярный")]), _c('div', {
      staticClass: "item-content"
    }, [_c('div', {
      staticClass: "name"
    }, [_vm._v(_vm._s(plan.name))]), _c('div', {
      staticClass: "price"
    }, [_vm.calculated ? [_vm._v(" " + _vm._s(_vm.calculated.items[i].finalPrice.toLocaleString()) + " р. / месяц ")] : [_vm._v("   ")]], 2), _c('div', {
      staticClass: "old-price"
    }, [_vm.calculated && _vm.calculated.items[i].basePrice !== _vm.calculated.items[i].finalPrice ? [_vm._v(" " + _vm._s(_vm.calculated.items[i].basePrice.toLocaleString()) + " р. ")] : _vm._e()], 2), _vm.calculatedForPeriod && _vm.calculatedForPeriod.months > 1 ? _c('div', {
      staticClass: "price-disclaimer"
    }, [_vm._v(" * при оплате за " + _vm._s(_vm.calculatedForPeriod.months) + " "), _vm.calculatedForPeriod.months == 3 || _vm.calculatedForPeriod.months == 24 ? _c('span', [_vm._v("месяца")]) : _c('span', [_vm._v("месяцев")])]) : _vm._e(), _c('div', {
      staticClass: "hr"
    }), _c('div', {
      staticClass: "features"
    }, [_c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v(" Внешняя аналитика Wildberries ")])], 1), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v("Внешняя аналитика Ozon")])], 1), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v("Продвинутые рейтинги")])], 1), _vm._m(13, true), _vm._m(14, true), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v("Дополнительные пользователи")])], 1), _vm._m(15, true), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v(" Подключение своего магазина по API ")])], 1), _vm._m(16, true), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _vm._m(17, true)], 1), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v("Подбор поисковых запросов")])], 1), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _vm._m(18, true)], 1), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v("Плагин для браузера")])], 1), _vm._m(19, true), _vm._m(20, true), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _vm._m(21, true)], 1), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v("Мониторинг РРЦ")]), _c('NewFeature', {
      staticClass: "label",
      attrs: {
        "type": "green"
      }
    })], 1), _vm._m(22, true), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v(" Репрайсер (управление ценами) ")]), _c('NewFeature', {
      staticClass: "label",
      attrs: {
        "type": "green"
      }
    })], 1), _vm._m(23, true), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _vm._m(24, true), _c('NewFeature', {
      staticClass: "label",
      attrs: {
        "type": "green"
      }
    })], 1), _vm._m(25, true), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _vm._m(26, true), _c('NewFeature', {
      staticClass: "label",
      attrs: {
        "type": "green"
      }
    })], 1), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v("Сравнение карточек")]), _c('NewFeature', {
      staticClass: "label",
      attrs: {
        "type": "green"
      }
    })], 1)]), _c('div', {
      staticClass: "action"
    }, [_c('PButton', {
      attrs: {
        "block": "",
        "tag": "a",
        "variant": "primary"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onChoose(plan);
        }
      }
    }, [_vm._v(_vm._s(_vm.isPlanActive(plan) ? "Продлить тариф" : "Выбрать тариф"))])], 1)])]) : _vm._e(), plan.main_position === 3 ? _c('div', {
      key: i,
      staticClass: "item",
      class: _vm.$mq
    }, [_c('div', {
      staticClass: "item-content"
    }, [_c('div', {
      staticClass: "name"
    }, [_vm._v(_vm._s(plan.name))]), _c('div', {
      staticClass: "price"
    }, [_vm.calculated ? [_vm._v(" " + _vm._s(_vm.calculated.items[i].finalPrice.toLocaleString()) + " р. / месяц ")] : [_vm._v("   ")]], 2), _c('div', {
      staticClass: "old-price"
    }, [_vm.calculated && _vm.calculated.items[i].basePrice !== _vm.calculated.items[i].finalPrice ? [_vm._v(" " + _vm._s(_vm.calculated.items[i].basePrice.toLocaleString()) + " р. ")] : _vm._e()], 2), _vm.calculatedForPeriod && _vm.calculatedForPeriod.months > 1 ? _c('div', {
      staticClass: "price-disclaimer"
    }, [_vm._v(" * при оплате за " + _vm._s(_vm.calculatedForPeriod.months) + " "), _vm.calculatedForPeriod.months == 3 || _vm.calculatedForPeriod.months == 24 ? _c('span', [_vm._v("месяца")]) : _c('span', [_vm._v("месяцев")])]) : _vm._e(), _c('div', {
      staticClass: "hr"
    }), _c('div', {
      staticClass: "features"
    }, [_c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v(" Внешняя аналитика Wildberries ")])], 1), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v("Внешняя аналитика Ozon")])], 1), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v("Продвинутые рейтинги")])], 1), _vm._m(27, true), _vm._m(28, true), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v("Дополнительные пользователи")])], 1), _vm._m(29, true), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v(" Подключение своего магазина по API ")])], 1), _vm._m(30, true), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _vm._m(31, true)], 1), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v("Подбор поисковых запросов")])], 1), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _vm._m(32, true)], 1), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v("Плагин для браузера")])], 1), _vm._m(33, true), _vm._m(34, true), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _vm._m(35, true)], 1), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v("Мониторинг РРЦ")]), _c('NewFeature', {
      staticClass: "label",
      attrs: {
        "type": "green"
      }
    })], 1), _vm._m(36, true), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v(" Репрайсер (управление ценами) ")]), _c('NewFeature', {
      staticClass: "label",
      attrs: {
        "type": "green"
      }
    })], 1), _vm._m(37, true), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _vm._m(38, true), _c('NewFeature', {
      staticClass: "label",
      attrs: {
        "type": "green"
      }
    })], 1), _vm._m(39, true), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v(" Тренды запросов: Без ограничений ")]), _c('NewFeature', {
      staticClass: "label",
      attrs: {
        "type": "green"
      }
    })], 1), _c('div', {
      staticClass: "features-item"
    }, [_c('PIconCheck', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v("Сравнение карточек")]), _c('NewFeature', {
      staticClass: "label",
      attrs: {
        "type": "green"
      }
    })], 1)]), _c('div', {
      staticClass: "action"
    }, [_c('PButton', {
      attrs: {
        "block": "",
        "tag": "a",
        "variant": "outlined"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onChoose(plan);
        }
      }
    }, [_vm._v(_vm._s(_vm.isPlanActive(plan) ? "Продлить тариф" : "Выбрать тариф"))])], 1)])]) : _vm._e()];
  }), _vm.plansLoaded ? _c('div', {
    staticClass: "item",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "item-content"
  }, [_c('div', {
    staticClass: "name"
  }, [_vm._v("Enterprise")]), _c('div', {
    staticClass: "price"
  }, [_vm._v("Цена по запросу")]), _c('div', {
    staticClass: "old-price",
    staticStyle: {
      "visibility": "hidden"
    }
  }, [_vm.calculated && _vm.calculated.items[0].basePrice !== _vm.calculated.items[0].finalPrice ? [_vm._v("   ")] : _vm._e()], 2), _vm.calculatedForPeriod && _vm.calculatedForPeriod.months > 1 ? _c('div', {
    staticClass: "price-disclaimer",
    staticStyle: {
      "visibility": "hidden"
    }
  }, [_vm._v("   ")]) : _vm._e(), _c('div', {
    staticClass: "hr"
  }), _c('p', {
    staticClass: "features-item"
  }, [_vm._v(" Тариф для крупных селлеров и производителей, с индивидуальным набором лимитов и инструментов под нужды вашего бизнеса ")]), _c('div', {
    staticClass: "features"
  }, [_c('div', {
    staticClass: "features-item"
  }, [_c('PIconCheck', {
    staticClass: "icon"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v("Персональный менеджер")])], 1), _c('div', {
    staticClass: "features-item"
  }, [_c('PIconCheck', {
    staticClass: "icon"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v("Онбординг и обучение")])], 1), _c('div', {
    staticClass: "features-item"
  }, [_c('PIconCheck', {
    staticClass: "icon"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v("Приоритетная поддержка")])], 1), _c('div', {
    staticClass: "features-item"
  }, [_c('PIconCheck', {
    staticClass: "icon"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v("Приоритетная разработка")])], 1)]), _c('PButton', {
    attrs: {
      "block": "",
      "tag": "a",
      "variant": "primary",
      "href": "https://t.me/SalesFinderSupportBot",
      "target": "_blank"
    }
  }, [_vm._v(" Связаться с менеджером ")])], 1)]) : _vm._e(), _c('modal-payment', {
    ref: "modalPayment"
  })], 2);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "features-item"
  }, [_c('div', {
    staticClass: "icon-placeholder"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v(" Запросов в сутки: "), _c('strong', [_vm._v("600")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "features-item"
  }, [_c('div', {
    staticClass: "icon-placeholder"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v(" Включено пользователей: "), _c('strong', [_vm._v("1")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "features-item"
  }, [_c('div', {
    staticClass: "icon-placeholder"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v(" Период анализа: "), _c('strong', [_vm._v("Последние 30 дней")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "features-item"
  }, [_c('div', {
    staticClass: "icon-placeholder"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v(" Кол-во токенов (магазинов): "), _c('strong', [_vm._v("2")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "text"
  }, [_vm._v(" Мониторинг позиций: "), _c('strong', [_vm._v("100 запросов")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "text"
  }, [_vm._v(" Контроль изменений: "), _c('strong', [_vm._v("3 проекта")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "features-item"
  }, [_c('div', {
    staticClass: "icon-placeholder"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v("Анализ WB")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "features-item"
  }, [_c('div', {
    staticClass: "icon-placeholder"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v(" Запросов в сутки: "), _c('strong', [_vm._v("600")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "text"
  }, [_vm._v(" Автоответы на отзывы: "), _c('strong', [_vm._v("2 сценария")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "features-item"
  }, [_c('div', {
    staticClass: "icon-placeholder"
  }), _c('div', {
    staticClass: "text"
  }, [_c('strong', [_vm._v(" ")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "features-item"
  }, [_c('div', {
    staticClass: "icon-placeholder"
  }), _c('div', {
    staticClass: "text",
    staticStyle: {
      "visibility": "hidden"
    }
  }, [_c('strong', [_vm._v(" Placeholder text Placeholder text Placeholder text Placeholder text ")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "text"
  }, [_vm._v(" Анализ поисковой выдачи "), _c('br'), _vm._v("WB ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "features-item"
  }, [_c('div', {
    staticClass: "icon-placeholder"
  }), _c('div', {
    staticClass: "text"
  }, [_c('strong', [_vm._v("10 запросов")]), _vm._v(" в сутки "), _c('strong', {
    staticStyle: {
      "visibility": "hidden"
    }
  }, [_vm._v(" Placeholder text Placeholder text ")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "features-item"
  }, [_c('div', {
    staticClass: "icon-placeholder"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v(" Запросов в сутки: "), _c('strong', [_vm._v("2000")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "features-item"
  }, [_c('div', {
    staticClass: "icon-placeholder"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v(" Включено пользователей: "), _c('strong', [_vm._v("2")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "features-item"
  }, [_c('div', {
    staticClass: "icon-placeholder"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v(" Период анализа: "), _c('strong', [_vm._v("Последние 90 дней")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "features-item"
  }, [_c('div', {
    staticClass: "icon-placeholder"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v(" Кол-во токенов (магазинов): "), _c('strong', [_vm._v("4")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "text"
  }, [_vm._v(" Мониторинг позиций: "), _c('strong', [_vm._v("200 запросов")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "text"
  }, [_vm._v(" Контроль изменений: "), _c('strong', [_vm._v("10 проектов")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "features-item"
  }, [_c('div', {
    staticClass: "icon-placeholder"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v("Анализ WB и OZON")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "features-item"
  }, [_c('div', {
    staticClass: "icon-placeholder"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v(" Запросов в сутки: "), _c('strong', [_vm._v("2000")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "text"
  }, [_vm._v(" Автоответы на отзывы: "), _c('strong', [_vm._v("5 сценариев")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "features-item"
  }, [_c('div', {
    staticClass: "icon-placeholder"
  }), _c('div', {
    staticClass: "text"
  }, [_c('strong', [_vm._v("1000")]), _vm._v(" проверок в сутки ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "features-item"
  }, [_c('div', {
    staticClass: "icon-placeholder"
  }), _c('div', {
    staticClass: "text"
  }, [_c('strong', [_vm._v("100 товаров")]), _vm._v(" под управлением "), _c('strong', {
    staticStyle: {
      "visibility": "hidden"
    }
  }, [_vm._v(" Placeholder text Placeholder text ")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "text"
  }, [_vm._v(" Анализ поисковой выдачи "), _c('br'), _vm._v("Ozon и WB ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "features-item"
  }, [_c('div', {
    staticClass: "icon-placeholder"
  }), _c('div', {
    staticClass: "text"
  }, [_c('strong', [_vm._v("50 запросов")]), _vm._v(" в сутки "), _c('strong', {
    staticStyle: {
      "visibility": "hidden"
    }
  }, [_vm._v(" Placeholder text Placeholder text ")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "text"
  }, [_vm._v(" Тренды запросов: за "), _c('br'), _vm._v("12 месяцев ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "features-item"
  }, [_c('div', {
    staticClass: "icon-placeholder"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v(" Запросов в сутки: "), _c('strong', [_vm._v("5000")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "features-item"
  }, [_c('div', {
    staticClass: "icon-placeholder"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v(" Включено пользователей: "), _c('strong', [_vm._v("5")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "features-item"
  }, [_c('div', {
    staticClass: "icon-placeholder"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v(" Период анализа: "), _c('strong', [_vm._v("Без ограничений")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "features-item"
  }, [_c('div', {
    staticClass: "icon-placeholder"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v(" Кол-во токенов (магазинов): "), _c('strong', [_vm._v("8")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "text"
  }, [_vm._v(" Мониторинг позиций: "), _c('strong', [_vm._v("500 запросов")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "text"
  }, [_vm._v(" Контроль изменений: "), _c('strong', [_vm._v("20 проектов")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "features-item"
  }, [_c('div', {
    staticClass: "icon-placeholder"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v("Анализ WB и OZON")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "features-item"
  }, [_c('div', {
    staticClass: "icon-placeholder"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v(" Запросов в сутки: "), _c('strong', [_vm._v("5000")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "text"
  }, [_vm._v(" Автоответы на отзывы: "), _c('strong', [_vm._v("20 сценариев")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "features-item"
  }, [_c('div', {
    staticClass: "icon-placeholder"
  }), _c('div', {
    staticClass: "text"
  }, [_c('strong', [_vm._v("5000")]), _vm._v(" проверок в сутки ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "features-item"
  }, [_c('div', {
    staticClass: "icon-placeholder"
  }), _c('div', {
    staticClass: "text"
  }, [_c('strong', [_vm._v("500 товаров")]), _vm._v(" под управлением "), _c('br'), _c('strong', [_vm._v("Расширенные лимиты")]), _vm._v(" за доп. плату ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "text"
  }, [_vm._v(" Анализ поисковой выдачи "), _c('br'), _vm._v("Ozon и WB ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "features-item"
  }, [_c('div', {
    staticClass: "icon-placeholder"
  }), _c('div', {
    staticClass: "text"
  }, [_c('strong', [_vm._v("150 запросов")]), _vm._v(" в сутки "), _c('br'), _c('strong', [_vm._v("Расширенные лимиты")]), _vm._v(" за доп. плату ")])]);
}];
export { render, staticRenderFns };