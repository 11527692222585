import PublicHeader from "../Header";
import PublicFooter from "../Footer";
import PlanCards from "./PlanCards";
import PeriodSelector from "./PeriodSelector";
import Review from "./Review";
import FireAlert from "@/components/Alert/FireAlert.vue";

//import WarningPrice from "@/components/Alert/WarningPrice.vue";
//import Clients from "./Clients";

import { aMONTH_DISCOUNT } from "../../../../common/monthDiscount";
//require('../../../../common/monthDiscount.js')

//console.log('Plans.vue: aMONTH_DISCOUNT=', aMONTH_DISCOUNT)
//console.log('Plans.vue: aMONTH_DISCOUNT_PERIOD=', aMONTH_DISCOUNT_PERIOD)

export default {
  meta: {
    title: "Тарифы и цены",
    meta: [{
      name: "description",
      content: "SalesFinder - Поиск прибыльных ниш, анализ цен и продаж конкурентов, трекинг позиций, данные по брендам и продавцам. Удобная аналитика маркетплейсов для увеличения ваших продаж!"
    }, {
      name: "viewport",
      content: "width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
    }]
  },
  data() {
    const periods = [
    //&: BLACK_FRIDAY-PROMO: 2023-11-14
    {
      name: "3 месяца",
      months: aMONTH_DISCOUNT[0].quantity,
      discount: aMONTH_DISCOUNT[0].percent
    }, {
      name: "6 месяцев",
      //months: 6, discount: 20
      months: aMONTH_DISCOUNT[1].quantity,
      discount: aMONTH_DISCOUNT[1].percent
    }, {
      name: "12 месяцев",
      //months: 12, discount: 30
      months: aMONTH_DISCOUNT[2].quantity,
      discount: aMONTH_DISCOUNT[2].percent
    }, {
      name: "24 месяца",
      //months: 24, discount: 50
      months: aMONTH_DISCOUNT[3].quantity,
      discount: aMONTH_DISCOUNT[3].percent
    }];

    //console.log('periods=', periods)

    return {
      periods,
      activePeriod: periods[3]
    };
  },
  methods: {
    openCarrotquestChat() {
      window.carrotquest.open();
    }
  },
  components: {
    PublicHeader,
    PlanCards,
    PublicFooter,
    PeriodSelector,
    Review,
    FireAlert
    //WarningPrice,
    // Clients,
  }
};